/* eslint-disable react/prop-types */
import { Container, Grid } from '@material-ui/core';
import styled from '@emotion/styled';
import { Link } from 'gatsby';
import React from 'react';
import { css } from '@emotion/react';
import theme from '../theme/theme';
import { FullWidth } from './featureSectionDark';
import TechBox from './techBox';

const SectionWrapper = styled.div`
    h1 span, h2 span {
        color: ${theme.primary};
    }
    padding: 55px 0px 80px 0px;
    @media screen and (max-width: 600px) {
        padding: 30px 0px;
        h1 {
            margin-top: 15px;
        }
    }
    position: relative;
    overflow: hidden;
`;

const CenterVertically = css`
    display: flex;
    align-items: center;
`;

const MWBox = styled.div`
    width: 95%;
    margin: 0 auto;
`;

const TechCard = styled.div`
    display: flex;
    padding: 15px;
    border-radius: 12px;
    box-shadow: 3px 5px 11px 5px rgba(0, 0, 0, 0.07);
    transition: 0.4s ease;
    height: 100%;
    align-items: center;
    h5 {
        margin-left: 10px;
        font-size: 17px;
        font-weight: 600;
    }
    svg {
        width: 50px;
        height: auto;
    }
    svg * {
        transition: 0.1s ease;
    }
    &:hover {
        box-shadow: 3px 5px 17px 5px rgba(0, 0, 0, 0.12);
    }
    &:not(:hover) {
        svg * {
            fill: #ddd;
        }
    }
`;

const TechGrid = css`
    margin-top: 20px;
    margin-bottom: 35px;
    @media screen and (min-width: 1000px) {
      width: 490px;
    }
`;

const FeatureSectionLight = ({
  title, subtitle, link, to, img, technologies, desc, invert, mb0, bottom,
}) => (
  <SectionWrapper style={{ paddingBottom: mb0 ? '0' : '' }}>
    <Container>
      <Grid container>
        {!invert && (
        <Grid item xs={12} sm={12} md={6} lg={6} css={CenterVertically}>
          <MWBox>
            {img}
          </MWBox>
        </Grid>
        )}
        <Grid item xs={12} sm={12} md={6} lg={6} css={CenterVertically}>
          <div css={FullWidth}>
            <h2>
              {title}
            </h2>
            {subtitle
            && <h5>{subtitle}</h5> }
            {desc && desc}
            {technologies
            && (
            <TechBox technologies={technologies} />
            )}
            {link
            && (
            <Link to={to} className="btn">
              {link}
            </Link>
            )}
          </div>
        </Grid>
        {invert && (
        <Grid item xs={12} sm={12} md={6} lg={6} css={CenterVertically}>
          <MWBox>
            {img}
          </MWBox>
        </Grid>
        )}
      </Grid>
      {bottom}
    </Container>
  </SectionWrapper>
);

export default FeatureSectionLight;
