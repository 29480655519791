/* eslint-disable react/prop-types */
import { Container, Grid } from '@material-ui/core';
import styled from '@emotion/styled';
import { Link } from 'gatsby';
import React from 'react';
import { css } from '@emotion/react';
import theme from '../theme/theme';

const SectionWrapper = styled.div`
    background-color: ${theme.bgDark};
    box-shadow: 0 8px 17px 2px rgba(0, 0, 0, 0.14),
        0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2);
    h2, p, h1, h5 {
        color: white;
    }
    h1 span, h2 span {
        color: ${theme.secondary};
    }
    padding: 60px 0px;
    @media screen and (max-width: 600px) {
        padding: 30px 0px;
    }
    position: relative;
    overflow: hidden;
`;

const CenterVertically = css`
    display: flex;
    align-items: center;
`;

const MWBox = styled.div`
    max-width: 90%;
    margin: 0 auto;
    @media screen and (max-width: 600px) {
        max-width: 70%;
    }
`;

const TechCard = styled.div`
    display: flex;
    padding: 15px;
    border-radius: 12px;
    background-color: #1c2129;
    height: 100%;
    align-items: center;
    h5 {
        color: white;
        font-size: 17px;
        font-weight: 600;
        margin-left: 10px;
    }
    svg {
        width: 50px;
        height: auto;
    }
    svg * {
        transition: 0.1s ease;
    }
    &:not(:hover) {
        svg * {
            fill: white;
        }
    }
`;

const TechGrid = css`
    margin-top: 20px;
    margin-bottom: 35px;
    @media screen and (min-width: 1000px) {
      width: 490px;
    }
`;

export const FullWidth = css`
`;

const FeatureSectionDark = ({
  title, subtitle, link, to, img, technologies, short,
}) => (
  <SectionWrapper>
    <div className="diagonal-overlay-light" />
    <Container>
      <Grid container>
        <Grid item xs={12} sm={12} md={6} lg={short ? 6 : 8} css={CenterVertically}>
          <div css={FullWidth}>
            <h2>
              {title}
            </h2>
            <h5>{subtitle}</h5>
            {technologies
            && (
            <Grid css={TechGrid} container spacing={2}>
              {technologies.map((el) => (
                <Grid item xs={6}>
                  <TechCard>
                    {el.icon}
                    <h5>
                      {el.name}
                    </h5>
                  </TechCard>
                </Grid>
              ))}
            </Grid>
            )}
            <Link to={to} className="btn btn-alt">
              {link}
            </Link>
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={short ? 6 : 4}>
          <MWBox>
            {img}
          </MWBox>
        </Grid>
      </Grid>
    </Container>
  </SectionWrapper>
);

export default FeatureSectionDark;
